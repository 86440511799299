<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Change Periode</strong>
        <a-date-picker
          class="ml-3"
          style="width: 100px;"
          v-model="input.periode"
          :allowClear="false"
          format="DD-MM-YYYY"
        />
        <a-button
          @click="changePeriode"
          class="btn btn-sm btn-outline-primary ml-2"
          >Save Changes</a-button
        >
        <!-- <strong class="pull-right">Selected Periode: {{ input.periode.format('DD-MM-YYYY') }}</strong> -->
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <!-- v-infinite-scroll="handleInfiniteOnLoad" -->
          <div
            class="demo-infinite-container"
            style="padding: 20px;height: 70vh;overflow: auto;"
            :infinite-scroll-distance="10"
          >
            <!-- :infinite-scroll-disabled="busy" -->
            <a-list :data-source="listdata">
              <a-list-item slot="renderItem" slot-scope="item">
                <!-- :description="item.email" -->
                <a-list-item-meta>
                  <strong
                    slot="title"
                    style="cursor: pointer;"
                    @click="changeperiode(item.periode)"
                    >{{
                      moment(item.periode, "YYYY-MM-DD").format("DD-MM-YYYY")
                    }}
                    <span class="ml-5 text-primary"
                      >Pindah ke periode ini</span
                    ></strong
                  >
                  <!-- <a-avatar
            slot="avatar"
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          /> -->
                </a-list-item-meta>
                <!-- <div>Content</div> -->
              </a-list-item>
              <!-- <div v-if="loading && !busy" class="demo-loading-container">
        <a-spin />
      </div> -->
            </a-list>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import infiniteScroll from 'vue-infinite-scroll'
import moment from 'moment'

export default {
  directives: { infiniteScroll },
  created() {
    this.input.periode =
      moment(this.$store.state.user.periode, 'YYYY-MM-DD') || moment()
    this.get()
  },
  data() {
    return {
      input: {
        periode: moment(),
      },
      columndefs: [],
      listdata: [],
    }
  },
  methods: {
    moment,
    async get() {
      var res = await lou.customUrlGet('ext/periode', false, false)
      console.log('res', res)
      if (res) {
        this.listdata = res.data
      }
    },
    async changeperiode(periode) {
      // console.log('this.input.periode', this.input.periode)
      // var nperiode = this.input.periode.format('YYYY-MM-DD')
      // await this.getSettings()
      var fd = { tgl: periode }
      // console.log('fd', fd)
      // fd.periode = nperiode
      // console.log('periode', nperiode)
      // console.log('fd', fd)
      // var res =
      var res = await lou.customUrlPost('auth/change_periode', fd, true, true)
      if (res) {
        this.input.periode = moment(periode, 'YYYY-MM-DD')
        // this.input.periode = moment(periode, 'YYYY-MM-DD')
        this.$store.commit('user/SET_STATE', {
          periode: periode,
        })
        localStorage.setItem(
          'userData',
          JSON.stringify(this.$store.state.user),
        )
        // lou.shownotif('Success', '"Periode" has been successfully changed!')
      }
    },
    async changePeriode() {
      // console.log('this.input.periode', this.input.periode)
      var nperiode = this.input.periode.format('YYYY-MM-DD')
      // await this.getSettings()
      var fd = { tgl: nperiode }
      // console.log('fd', fd)
      // fd.periode = nperiode
      // console.log('periode', nperiode)
      // console.log('fd', fd)
      // var res =
      var res = await lou.customUrlPost('auth/change_periode', fd, true, true)
      if (res) {
        this.input.periode = moment(nperiode, 'YYYY-MM-DD')
        this.$store.commit('user/SET_STATE', {
          periode: nperiode,
        })
        localStorage.setItem(
          'userData',
          JSON.stringify(this.$store.state.user),
        )
        // lou.shownotif('Success', '"Periode" has been successfully changed!')
        this.editAble = false
      }
    },
  },
}
</script>

<style></style>
